<template>
  <v-container
    id="client-forms"
    fluid
    tag="section"
  >
    <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          :size="150"
          :width="8"
          color="green"
        />
      </div>
    </v-container>
    <v-row justify="center">
      <v-col
        v-if="!loading"
        cols="12"
        md="12"
        class="mt-0 mb-0 pt-0 pb-0"
      >
        <v-breadcrumbs
          class="mt-0 mb-0 pt-0 pb-0"
          :items="[{text: 'Ваш центр', to: '/'}, {text: 'Лицензии', to: '/patents'}, {text: title}]"
          large
        />
      </v-col>
      <v-col
        cols="12"
        md="10"
        lg="8"
      >
        <base-material-alert
          v-if="error"
          dark
          color="error"
          dismissible
        >
          {{ error }}
        </base-material-alert>

        <base-material-card
          v-if="!loading"
          color="success"
          icon="mdi-badge-account"
          class="px-5 py-3"
          :title="title"
        >
          <v-form
            ref="form"
            class="mt-5"
            lazy-validation
          >
            <v-row
              justify="center"
            >
              <v-col
                v-if="patent.status === 0"
                cols="12"
                md="12"
              >
                <base-material-alert
                  v-if="patent.status === 0"
                  color="warning"
                  dark
                  icon="mdi-zip-disk"
                >
                  Данный элемент в архиве!
                </base-material-alert>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="patent.name"
                    required
                    label="Название*"
                    :error-messages="fieldError('name')"
                  />
                </v-col>
                <v-col
                  v-if="patent.photo"
                  cols="12"
                  md="12"
                >
                  <v-card
                    flat
                    tile
                    class="d-flex"
                  >
                    <v-img
                      :src="imageLink(patent.photo)"
                      :lazy-src="imageLink(patent.photo)"
                      aspect-ratio="1"
                      class="grey lighten-2"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          />
                        </v-row>
                      </template>
                    </v-img>
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-file-input
                    v-model="photo"
                    :error-messages="fieldError('photo')"
                    color="deep-purple accent-4"
                    label="Фото"
                    show-size
                    placeholder="Выберите фото"
                    prepend-icon="mdi-paperclip"
                    outlined
                  />
                </v-col>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-card-actions class="pl-0">
                  <v-btn
                    color="success"
                    min-width="100"
                    @click="prepareSavePatent()"
                  >
                    Сохранить
                  </v-btn>

                  <v-btn
                    v-if="patent.status === 1"
                    color="warning"
                    min-width="100"
                    @click="archive()"
                  >
                    Переместить в архив
                  </v-btn>

                  <v-btn
                    v-if="patent.status === 0 && patent.id > 0"
                    color="warning"
                    min-width="100"
                    @click="unarchive()"
                  >
                    Вернуть из архива
                  </v-btn>

                  <v-btn
                    v-if="patent.status === 0 && patent.id > 0"
                    color="error"
                    class="ma-1"
                    min-width="100"
                    @click="confirmDialog = true"
                  >
                    Удалить
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="confirmDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Удалить элемент?
        </v-card-title>

        <v-card-text>
          Лицензия будет удалена без возможности восстановления.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="darken-1"
            text
            @click="confirmDialog = false"
          >
            Отмена
          </v-btn>

          <v-btn
            color="error darken-1"
            text
            @click="removePatent(patent.id)"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import patentApi from '../services/PatentApi'

  export default {
    props: ['id'],
    data () {
      return {
        photo: null,
        patent: {
          id: null,
          name: null,
        },
        loading: true,
        error: null,
        fieldsErrors: [],
        fieldError (fieldName) {
          let errors = []
          if (this.fieldsErrors) {
            this.fieldsErrors.filter(field => {
              if (field.field === fieldName) {
                errors.push(field.message)
              }
            })
          }
          return errors
        },
        confirmDialog: false,
      }
    },

    computed: {
      title () {
        if (this.patent.name) return this.patent.name
        if (this.id === 'create') return 'Добавить лицензию'
        return '#' + this.id
      },
    },

    mounted () {
      document.title = this.title + ' | ' + process.env.VUE_APP_NAME
      moment.locale('ru')
      this.fetchData()
    },

    methods: {
      fetchData () {
        if (this.id === 'create') {
          this.loading = false
          this.patent = {
            id: null,
            name: null,
            created: moment().format('YYYY-MM-DD HH:mm:ss'),
            status: 1,
          }
        } else {
          patentApi
            .fetchPatent(this.id)
            .then(response => {
              this.patent = response
              this.loading = false
              document.title = this.title + ' | ' + process.env.VUE_APP_NAME
            }).catch(error => {
              this.error = this.pretty(error.response.data)
            })
        }
      },

      back () {
        this.$router.push('/')
      },

      prepareSavePatent () {
        if (this.photo) {
          this.getBase64(this.photo).then(
            data => {
              this.patent.photoData = data
              this.savePatent()
            })
        } else {
          this.savePatent()
        }
      },

      savePatent () {
        if (this.patent.id) {
          this.error = null
          this.fieldsErrors = []
          this.loading = true
          patentApi
            .updatePatent(this.patent)
            .then(response => {
              this.patent = response
              this.fetchData()
            })
            .catch(error => {
              let data = error.response.data
              if (error.response.status === 422) {
                this.fieldsErrors = data
              } else {
                this.error = this.pretty(data)
              }
              this.loading = false
            })
        } else {
          patentApi
            .createPatent(this.patent)
            .then(response => {
              this.patent = response
              this.$router.push('/patent/' + this.patent.id)
            })
            .catch(error => {
              let data = error.response.data
              if (error.response.status === 422) {
                this.fieldsErrors = data
              } else {
                this.error = this.pretty(data)
              }
              this.loading = false
            })
        }
      },

      removePatent () {
        this.confirmDialog = false
        this.loading = true
        patentApi
          .deletePatent(this.patent.id)
          .then(response => {
            // this.template = response
            this.loading = false
            this.$router.go(-1)
          }).catch(error => {
            this.error = this.pretty(error.response.data)
          })
      },

      archive () {
        this.patent.status = 0
        this.savePatent()
      },
      unarchive () {
        this.patent.status = 1
        this.savePatent()
      },
      pretty (value) {
        if (value.message) {
          return value.message
        }
        return JSON.stringify(value, null, 2)
      },
      formatDate (date) {
        if (!date) return null
        return moment(date).format('DD.MM.YYYY')
      },

      imageLink (photo) {
        return process.env.VUE_APP_OLD_URL + 'images/patents/' + photo
      },

      getBase64 (file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = error => reject(error)
        })
      },
    },
  }
</script>
