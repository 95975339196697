var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "client-forms", fluid: "", tag: "section" } },
    [
      _vm.loading
        ? _c("v-container", [
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("v-progress-circular", {
                  attrs: {
                    indeterminate: "",
                    size: 150,
                    width: 8,
                    color: "green"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          !_vm.loading
            ? _c(
                "v-col",
                {
                  staticClass: "mt-0 mb-0 pt-0 pb-0",
                  attrs: { cols: "12", md: "12" }
                },
                [
                  _c("v-breadcrumbs", {
                    staticClass: "mt-0 mb-0 pt-0 pb-0",
                    attrs: {
                      items: [
                        { text: "Ваш центр", to: "/" },
                        { text: "Лицензии", to: "/patents" },
                        { text: _vm.title }
                      ],
                      large: ""
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "10", lg: "8" } },
            [
              _vm.error
                ? _c(
                    "base-material-alert",
                    { attrs: { dark: "", color: "error", dismissible: "" } },
                    [_vm._v("\n        " + _vm._s(_vm.error) + "\n      ")]
                  )
                : _vm._e(),
              !_vm.loading
                ? _c(
                    "base-material-card",
                    {
                      staticClass: "px-5 py-3",
                      attrs: {
                        color: "success",
                        icon: "mdi-badge-account",
                        title: _vm.title
                      }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          staticClass: "mt-5",
                          attrs: { "lazy-validation": "" }
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            [
                              _vm.patent.status === 0
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _vm.patent.status === 0
                                        ? _c(
                                            "base-material-alert",
                                            {
                                              attrs: {
                                                color: "warning",
                                                dark: "",
                                                icon: "mdi-zip-disk"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                Данный элемент в архиве!\n              "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          required: "",
                                          label: "Название*",
                                          "error-messages": _vm.fieldError(
                                            "name"
                                          )
                                        },
                                        model: {
                                          value: _vm.patent.name,
                                          callback: function($$v) {
                                            _vm.$set(_vm.patent, "name", $$v)
                                          },
                                          expression: "patent.name"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm.patent.photo
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "12" } },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass: "d-flex",
                                              attrs: { flat: "", tile: "" }
                                            },
                                            [
                                              _c("v-img", {
                                                staticClass: "grey lighten-2",
                                                attrs: {
                                                  src: _vm.imageLink(
                                                    _vm.patent.photo
                                                  ),
                                                  "lazy-src": _vm.imageLink(
                                                    _vm.patent.photo
                                                  ),
                                                  "aspect-ratio": "1"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "placeholder",
                                                      fn: function() {
                                                        return [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              staticClass:
                                                                "fill-height ma-0",
                                                              attrs: {
                                                                align: "center",
                                                                justify:
                                                                  "center"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-progress-circular",
                                                                {
                                                                  attrs: {
                                                                    indeterminate:
                                                                      "",
                                                                    color:
                                                                      "grey lighten-5"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  4034393411
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _c("v-file-input", {
                                        attrs: {
                                          "error-messages": _vm.fieldError(
                                            "photo"
                                          ),
                                          color: "deep-purple accent-4",
                                          label: "Фото",
                                          "show-size": "",
                                          placeholder: "Выберите фото",
                                          "prepend-icon": "mdi-paperclip",
                                          outlined: ""
                                        },
                                        model: {
                                          value: _vm.photo,
                                          callback: function($$v) {
                                            _vm.photo = $$v
                                          },
                                          expression: "photo"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c(
                                    "v-card-actions",
                                    { staticClass: "pl-0" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "success",
                                            "min-width": "100"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.prepareSavePatent()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Сохранить\n                "
                                          )
                                        ]
                                      ),
                                      _vm.patent.status === 1
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "warning",
                                                "min-width": "100"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.archive()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Переместить в архив\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.patent.status === 0 &&
                                      _vm.patent.id > 0
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "warning",
                                                "min-width": "100"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.unarchive()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Вернуть из архива\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.patent.status === 0 &&
                                      _vm.patent.id > 0
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "ma-1",
                                              attrs: {
                                                color: "error",
                                                "min-width": "100"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.confirmDialog = true
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Удалить\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.confirmDialog,
            callback: function($$v) {
              _vm.confirmDialog = $$v
            },
            expression: "confirmDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("\n        Удалить элемент?\n      ")
              ]),
              _c("v-card-text", [
                _vm._v(
                  "\n        Лицензия будет удалена без возможности восстановления.\n      "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          Отмена\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.removePatent(_vm.patent.id)
                        }
                      }
                    },
                    [_vm._v("\n          Удалить\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }